import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styles from './Card.module.scss';
import getDuration from '../../utils/get-duration';

const Card = ({
  image, title, preTitle, subTitle, link, cooktime, id
}) => (
  <div className={styles['card']}>
    <AniLink color="white" paintDrip duration={0.5} to={link}>
      <div className={styles['card__cover__container']}>
        <div
          className={styles['card__cover']}
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
      <div className={styles['card__content']}>
        <div className={styles['card__title']}>
          {preTitle} <span className={styles['card__empasize']}>{title}</span>
        </div>
        <div className={styles['card__details']}>
          <div className="yotpo bottomLine" data-yotpo-product-id={id}></div>
          <div className={styles['card__cooktime']}>{getDuration(cooktime)}</div>
          <div className={styles['card__subtitle']}>{subTitle}</div>
        </div>
      </div>
    </AniLink>
  </div>
);

export default Card;
